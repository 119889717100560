import React from 'react';

import { Layout } from '../components/layout/layout';
import { SEO } from '../components/layout/seo';
import { useConfig } from '../hooks/useConfig';

export default function PageNotFound({ location }) {
  const {
    mainSEO: { title }
  } = useConfig();
  return (
    <Layout location={location} title={title}>
      <SEO title="404: Not Found" />
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  );
}
